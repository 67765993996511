// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "../../../node_modules/rescript/lib/es6/caml_option.js";
function getVersion(param) {
  var isPages = Caml_option.nullable_to_opt(document.querySelector("#quicknav, maker-show")) !== undefined;
  if (isPages) {
    return "v2";
  } else {
    return "v3";
  }
}
export { getVersion };
/* No side effect */