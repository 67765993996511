// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Env$Showside from "../utils/Env.bs.js";
import * as Caml_js_exceptions from "../../../../node_modules/rescript/lib/es6/caml_js_exceptions.js";
import * as WindowSize$Showside from "../utils/WindowSize.bs.js";
import * as Breakpoint2$Showside from "../utils/Breakpoint2.bs.js";
function makeApiUrl(apiHostname, live, backup, configId, breakpoint, trimTreeByReferrer, param) {
  return apiHostname + "/api/get_config?config_id=" + configId + (live ? "&live=true" : "") + Belt_Option.mapWithDefault(backup, "", function (backup) {
    return "&backup=" + backup;
  }) + Belt_Option.mapWithDefault(trimTreeByReferrer, "", function (trimTreeByReferrer) {
    return "&trim_tree_by_referrer=" + trimTreeByReferrer;
  }) + "&showside_format=true&breakpoint=" + breakpoint + ("&node_limit=" + String(30)) + "&paginate=true";
}
function preloadConfig(enableTrimmingTreeByReferrerOpt, breakpointOverride, configId) {
  var enableTrimmingTreeByReferrer = enableTrimmingTreeByReferrerOpt !== undefined ? enableTrimmingTreeByReferrerOpt : false;
  var $$location = window.location;
  var trimTreeByReferrer = enableTrimmingTreeByReferrer ? encodeURIComponent($$location.origin + $$location.pathname) : undefined;
  var match;
  try {
    var url = new URL(window.location.href);
    var navParams = url.searchParams;
    var devMode = Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(navParams.get("QNDevMode")), /* Production */1, Env$Showside.getApiEnv);
    var previewMode = navParams.get("QNPreviewMode");
    var backup;
    if (previewMode == null) {
      backup = undefined;
    } else {
      var match$1 = previewMode.match(/^T([0-9]+)$/);
      if (match$1 !== null && match$1.length === 2) {
        var timestamp = match$1[1];
        backup = timestamp !== undefined ? timestamp : undefined;
      } else {
        backup = undefined;
      }
    }
    var live = previewMode == null || previewMode !== "HEAD" ? true : false;
    match = [Env$Showside.getApiHostname(devMode), live, backup];
  } catch (raw_e) {
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    console.error("Failed to get referrer, apiHostname, live, backup for preloading:", e);
    match = [Env$Showside.apiHostnameProduction, true, undefined];
  }
  var breakpoint = Breakpoint2$Showside.toString(Belt_Option.getWithDefault(breakpointOverride, Breakpoint2$Showside.calc(WindowSize$Showside.getWidth(undefined))));
  var link = document.createElement("link");
  link.setAttribute("rel", "preload");
  link.setAttribute("as", "fetch");
  link.setAttribute("fetchpriority", "high");
  link.setAttribute("href", makeApiUrl(match[0], match[1], match[2], configId, breakpoint, trimTreeByReferrer, undefined));
  link.setAttribute("crossorigin", "anonymous");
  Belt_Option.forEach(Caml_option.nullable_to_opt(document.querySelector("head")), function (__x) {
    __x.appendChild(link);
  });
}
var nodeLimit = 30;
export { nodeLimit, makeApiUrl, preloadConfig };
/* Env-Showside Not a pure module */