// Generated by ReScript, PLEASE EDIT WITH CARE

var Console = {};
var unwrapJsError = function (exn) {
  if (typeof exn != "object") {
    return exn;
  }
  if (!exn.hasOwnProperty("RE_EXN_ID") || !exn.hasOwnProperty("_1")) {
    return exn;
  }
  if (typeof exn["RE_EXN_ID"] == "string" && exn["RE_EXN_ID"].includes("JsError") && typeof exn["_1"] == "object" && exn["_1"] instanceof Error) {
    return exn["_1"];
  } else {
    return exn;
  }
};
var Exn = {
  unwrapJsError: unwrapJsError
};
export { Console, Exn };
/* No side effect */