// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as $$Promise from "../../../../node_modules/@ryyppy/rescript-promise/lib/es6_global/src/Promise.bs.js";
import * as JsUtils$Showside from "./JsUtils.bs.js";
function $$catch(promise, fn) {
  return $$Promise.$$catch(promise, function (e) {
    return Curry._1(fn, JsUtils$Showside.Exn.unwrapJsError(e));
  });
}
export { $$catch };
/* No side effect */